import { useState, useEffect, useContext, createContext} from 'react';
import { auth, db } from 'config/firebase';
import axios from 'axios';
import { setCookie,eraseCookie } from '../utilities/cookies/cookies';
import {useRouter} from 'next/router';
// import {auth} from 'firebase/auth'

const authContext = createContext({ user: {} });
const { Provider } = authContext;
export function AuthProvider(props){
    const auth = useAuthProvider();
    return <Provider value={auth}>{props.children}</Provider>;
}
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates an auth object and handles it's state
const useAuthProvider = () => {
    const [user, setUser] = useState(null);
    const router = useRouter();

    const createUser = async(user) => {
        await axios.post(`${process.env.HOST}/users`,{
            UID: user.uid,
            email: user.email,
            confirmed: true,
            username: user.name,
            password: user.password,
            phone: user.phone
        }).then(async(response)=>{
            // console.log('RESPONSE n creating server',response);
            // console.log('Data to create user: ',user.email,user.password);
            
            await axios.post(`${process.env.HOST}/auth/local`,{
                'identifier': user.email,
                'password': user.password
            }).then((responseauth)=>{
                // console.log("respuesta aauth",responseauth)
                setCookie('session',responseauth.data.jwt,7);
                // console.log('responseauth',responseauth);
                user['strapiId'] = response.data.id;
                // console.log('AUTH USER:',user);

                return db
                .collection('users')
                .doc(user.uid)
                .set(user)
                .then(() => {
                    sessionStorage.setItem("userId",user.strapiId);
                    setUser(user);
                    // console.log("set User");
                    return user;
                })
                .catch((error) => {
                    return { error };
                });
            }).catch(err=>{
                console.lg("error al aaautenticar",err)
            })
        }).catch((error)=>{
            return console.log('El user no se pudo crear en strapi',error);
        }) 
    };
    const signUp = ({ name, phone, email, password }) => {
        return auth
            .createUserWithEmailAndPassword(email, password)
            .then((response) => {
                auth.currentUser.sendEmailVerification();
                return createUser({ uid: response.user.uid, email, name, phone, password });
            })
            .catch((error) => {
                return { error };
            });
    };
    const signIn = ({ email, password }) => {

        return auth
         .signInWithEmailAndPassword(email, password)
         .then(async (response) => {
            //  console.log('RESPONSE',response);
            setUser(response.user)
            //  console.log('USER',user);
             
            return await axios.post(`${process.env.HOST}/auth/local`,{
                identifier: email,
                password: password
            }).then((responseauth)=>{
                // console.log("respuesta aauth",responseauth)
                setCookie('session',responseauth.data.jwt,7);
                // user['strapiId'] = responseauth.data.user.id;
                // console.log('AUTH USER:',user);
                return response.user;
                // return db
                // .collection('users')
                // .doc(user.uid || response.user.uid)
                // .set(user || response.user)
                // .then(async() => {
                //     sessionStorage.setItem("userId",user.strapiId);
                //     setUser(user);
                //     await getUserAdditionalData(user);
                //     console.log("set User");
                //     if (user!=null) return user;
                //     return response.user
                // })
                // .catch((error) => {
                //     return { error };
                // });
            }).catch(err=>{
                console.log("error al aaautenticar",err);
                return err;
            })
         })
         .catch((error) => {
            console.log('error signin ',error);
            throw error; 
         });
    };
    const signOut = () => {
        return auth.signOut().then(() => {
            eraseCookie('session');
            eraseCookie('jwt');
            setUser(null);
            // console.log('USUARIO LOG OUt',user);

            router.push('/')
        }).catch((error)=>{
            console.log('error signin out',error);
        });
    };      
    const getUserAdditionalData = (user) => {
        return db
         .collection('users')
         .doc(user.uid)
         .get()
         .then((userData) => {
            //  console.log('user data: ',userData.data());
             
          if (userData.data()) {
           setUser(userData.data());
        //    console.log('1');           
          }
         });
    };
    const handleAuthStateChanged = (user) => {
        setUser(user);
        if (user) {
            getUserAdditionalData(user);
        }
    };
    const sendPasswordResetEmail = (email) => {
        return auth.sendPasswordResetEmail(email).then((response) => {
            return response;
        });
    };
    const updatePassword = (currentPassword,new_pass) => {
        // console.log('User in updaate',user);
        // console.log('User in updaate',auth.currentUser);
        // console.log('provider',Provider);
        // console.log('credential',auth.credential());
        // console.log('credential',auth.EmailAuthProvider);
        // let credential = auth.EmailAuthProvider.credential(
        //     user.email, 
        //     currentPassword
        // );
        const credential = auth.EmailAuthProvider.credential(user.email, currentPassword);
        return auth.currentUser.reauthenticateWithCredential(credential).then((res) => {
            // var user = firebase.auth().currentUser;
            // console.log("Usuario updated",res);
            // console.log("Usuario updated",auth.currentUser);
            auth.currentUser.updatePassword(new_pass).then((res) => {
            //   console.log("Password updated!");
              return res;
            }).catch((error) => { console.log("error al actualizar en strapi",error); });
        }).catch((error) => { console.log("error firebase",error); });
        // auth.currentUser.updatePassword(new_pass).then((response) => {
        //     return response;
        // });
    };
    useEffect(() => {
        const unsub = auth.onAuthStateChanged(handleAuthStateChanged);
        
        return () => unsub();
    }, []);
    useEffect(() => {
        if (user?.uid) {
          // Subscribe to user document on mount
            const unsubscribe = db
                .collection('users')
                .doc(user.uid)
                .onSnapshot((doc) => setUser(doc.data()));
            return () => unsubscribe();
        }
    }, []);
    return {
        user,
        signUp,
        signIn,
        signOut,
        sendPasswordResetEmail,
        updatePassword
    };
};