// Motofacil theme 
import QuicksandWoff2 from 'public/fonts/quicksand-regular.woff2';
import QuicksandTTF from 'public/fonts/Quicksand-VariableFont_wght.ttf';

const quicksand = {
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Quicksand'),
    local('Quicksand-Regular'),
    url(${QuicksandWoff2}) format('woff2'),
    url(${QuicksandTTF}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = {
    palette: {
        primary: {
          main:  '#f44336',
          light: '#ff7961',
          dark:  '#ba000d'
        },
        secondary: {
          main: '#303f9f',
          light: '#666ad1',
          dark: '#011870',
        },
        tertiary: {
            main: '#546e7a',
            light: '#819caa',
            dark: '#2a434e',
        }
    },
    typography: {
      fontFamily: [
        'Quicksand',        
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Quicksand',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [quicksand],
        },
      },
    },

}

export default theme;