import "../styles/globals.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { AuthProvider } from "hooks/useAuth";
import { CssBaseline } from "@material-ui/core";
import * as gtag from "../utilities/lib/gtag";

// theme
import theme from "../utilities/theme/theme";
import { useRouter } from "next/router";
import { useEffect } from "react";

const mainTheme = createMuiTheme(theme);

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <AuthProvider>
        <Component {...pageProps} />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default MyApp;
